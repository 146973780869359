import React from "react"
import ReactPlayer from "react-player/youtube"
import YouTube from "react-youtube"
function Video({ video }) {
  const opts = {
    playerVars: {
      autoplay: 0,
      controls: 0,
      showinfo: 0,
      modestbranding: 1,
      autohide: 1,
    },
  }
  const { title, vimeoInfo } = video.node

  return (
    <div className="space-y-4  block">
      <div className=" w-full embed-container">
        <ReactPlayer
          url={`https://www.youtube.com/watch?v=${vimeoInfo.youtubeid}`}
          light
          autoplay
        />
      </div>
      <div className="space-y-2">
        <div>
          <h3 className="text-xl font-bold">{title}</h3>
        </div>
      </div>
    </div>
  )
}

export default Video
