import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import GalleriVideo from "../components/video/GalleriVideo"

function VideoPage() {
  return (
    <Layout>
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title="Video"
      />

      <section>
        {" "}
        <GalleriVideo />
      </section>
    </Layout>
  )
}

export default VideoPage
