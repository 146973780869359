import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Video from "./Video";

function GalleriVideo() {
  const data = useStaticQuery(graphql`
    {
      allWpVideo {
        edges {
          node {
            id
            title
            vimeoInfo {
              vimeoLank
              beskrivning
              youtubeid
            }
          }
        }
      }
    }
  `);
  const videor = data.allWpVideo.edges;
  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 px-4 py-8 md:p-16">
      {videor.map((video) => {
        return <Video key={video.node.id} video={video} />;
      })}
    </div>
  );
}

export default GalleriVideo;
